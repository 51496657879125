











import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    OForgotPassword: () => import('@/components/organisms/o-forgot-password.vue')
  }
})
